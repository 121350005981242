import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase";

// Initialize Firebase
var config = {
  apiKey: "AIzaSyAe1R_lB9heQXUgJf0GgPQpcLFwDo_OQVA",
  authDomain: "innopunch-website.firebaseapp.com",
  databaseURL: "https://innopunch-website.firebaseio.com",
  projectId: "innopunch-website",
  storageBucket: "innopunch-website.appspot.com",
  messagingSenderId: "145492753517"
};
firebase.initializeApp(config);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
