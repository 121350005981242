import React from "react";

export default class About extends React.Component {
  render() {
    return (
      <div id="contact" className="w-70 sm-w80 ">
        <h2 className="tagline bold">Contact Us</h2>
        <p className="black">
          Email us at:{" "}
          <a className="black" href="mailto:contact@innopunch.com">
            contact@innopunch.com
          </a>
        </p>
      </div>
    );
  }
}
