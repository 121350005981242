import React from "react";

export default class About extends React.Component {
  render() {
    return (
      <div id="work" className="w-70 sm-w80 ">
        <h2 className="tagline bold">How We Work</h2>
        <div className="light w-08 sm-w80p">
          <ul className="no-bullet img-bullets">
            <li>
              <div className="inline bullet bold">
                <div className="bullet-icon">
                  <p>1</p>
                </div>
              </div>
              <p className="inline">
                <span className="bold">Sending bold messages</span> to establish
                a shared framework among your innovation teams.
              </p>
            </li>
            <li>
              <div className="inline bullet bold">
                <div className="bullet-icon">
                  <p>2</p>
                </div>
              </div>
              <p className="inline">
                Reinforcing your innovative projects by{" "}
                <span className="bold">sharpening the strategic approach</span>{" "}
                and reducing -and accelerating- the delivery cycles.
              </p>
            </li>

            <li>
              <div className="inline bullet bold">
                <div className="bullet-icon">
                  <p>3</p>
                </div>
              </div>
              <p className="inline">
                Helping you design a{" "}
                <span className="bold">supervised and repeatable process</span>{" "}
                to industrializing the exploration of innovative ideas.
              </p>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
