import React from "react";

export default class References extends React.Component {
  render() {
    const refs = [
      "tas.png",
      "sg.png",
      "continental.png",
      "bp.png",
      "ynov.png",
      "ce.png",
      "clarins.png",
      "pf.png",
      "formulaction.png",
      "msa.png",
      "ga.png",
      "poste.png",
      "imt.png",
      "prefecture.png",
      "apojee.png",
      "oracle.png",
      "edel.png",
      "momentum.png",
      "village.png",
      "starter.png",
      "asco.png",
      "nobatek.png"
    ];
    return (
      <div id="references" className="w-70 sm-w80">
        <h2 className="tagline bold">About InnoPunch</h2>
        <p className="mg-bt-3 light">
          We work with companies and startup incubators on educating teams and
          accelerating innovative projects.
        </p>

        <ul className="no-bullet references-logo-list inline flex wrap jc-between">
          {refs.map(ref => {
            return (
              <li key={ref}>
                <img
                  className="img-li"
                  src={"/assets/img/logos/logo_" + ref}
                  alt=""
                  height="70"
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
