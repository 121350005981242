import React from "react";
import "./Header.css";

export default class Header extends React.Component {
  scrollToDiv(event) {
    event.preventDefault();
    const id = event.target.attributes.href.value.replace("#", "");
    const div = document.getElementById(id);
    const header = document.getElementById("header-fixed");
    const offsetTop = div.offsetTop - header.offsetHeight * 1.2;
    window.scrollTo(0, offsetTop);
  }

  render() {
    const props = this.props;
    return (
      <header
        id={`header-${props.dark ? "fixed" : ""}`}
        className={`header flex jc-center    ${
          props.dark ? "dark fixed" : ""
        } ${props.show ? "load" : "hide"}`}
      >
        <div className="flex jc-between items-center w-75 sm-w100">
          <img
            src="/assets/img/apple-icon-76x76.png"
            width="75"
            height="75"
            alt=""
            className="logo"
          />

          <ul className="no-bullet inline light">
            <li className="sm-hide">
              <a href="#references" onClick={this.scrollToDiv}>
                Customers
              </a>
            </li>
            <li className="sm-hide">
              <a href="#team" onClick={this.scrollToDiv}>
                Team
              </a>
            </li>
            <li className="sm-hide">
              <a href="#work" onClick={this.scrollToDiv}>
                Process
              </a>
            </li>
            <li className="sm-hide">
              <a href="#contact" onClick={this.scrollToDiv}>
                Contact
              </a>
            </li>
            <li className="mg-lf-2">
              <a
                href="https://www.medium.com/innopunch"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-15x fa-medium" aria-label="Medium icon" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCrLYr6Fh4QL0h-KcZtwOB4w"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="fab fa-15x fa-youtube"
                  aria-label="Youtube icon"
                />
              </a>
            </li>
          </ul>
        </div>
      </header>
    );
  }
}
