import React from "react";
import PeopleCard from "./PeopleCard";

export default class About extends React.Component {
  render() {
    const cherif = {
      name: "Chérif Mili",
      title: "Management and Strategy",
      photoURL: "assets/img/cherif.jpg",
      linkedinURL: "https://www.linkedin.com/in/cherifmili/",
      hashTags: [
        "#Lean Startup",
        "#Innovation Strategy",
        "#Portfolio management"
      ]
    };
    const maxime = {
      name: "Maxime Pawlak",
      title: "Management and Tech",
      photoURL: "assets/img/maxime.png",
      linkedinURL: "https://www.linkedin.com/in/maxime-pawlak-/",
      hashTags: ["#Lean Startup", "#Pitch", "#Web & Data"]
    };
    return (
      <div id="team" className="w-70 sm-w80 ">
        <h2 className="tagline bold">Our Core Team</h2>
        <p className="mg-bt-0 light">
          We share the belief that the{" "}
          <span className="bold">real innovation game</span> starts once your
          idea is written down - and is all about making the right decisions. We
          think this ability comes with{" "}
          <span className="bold">experience and deep understanding</span> of
          innovation mechanics which is our main focus.
        </p>
        <div className="flex wrap jc-around text-center mg-tp-3">
          <PeopleCard {...cherif} />
          <PeopleCard {...maxime} />
        </div>
      </div>
    );
  }
}
