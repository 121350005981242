import React from "react";

const PeopleCard = props => {
  return (
    <div className="people-card">
      <img className="rounded mg-bt-2" src={props.photoURL} alt={props.name} />
      <div className="size-08">
        <div>
          <a
            href={props.linkedinURL}
            target="_blank"
            rel="noopener noreferrer"
            className="black  decoration-none "
          >
            <i className="fab fa-linkedin blue-linkedin" />
            &nbsp;
            {props.name}
          </a>
        </div>
        <div className="light">{props.title}</div>
        <ul className="light no-bullet ">
          {props.hashTags.map(tag => {
            return <li key={tag}>{tag}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

export default PeopleCard;
