import React, { Component } from "react";
import "./App.css";
import firebase from "firebase";
import References from "./References";
import Header from "./Header";
import Team from "./Team";
import Work from "./Work";
import Contact from "./Contact";

let ticking = false;
class App extends Component {
  constructor() {
    super();
    this.emailInput = React.createRef();
    this.state = {
      subscription: {
        inProgress: false,
        success: false,
        message: undefined
      },
      ticking: false,
      lastScrollY: 0,
      hideHeader: false,
      showDarkHeader: false
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.mainRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    if (!ticking) {
      // if (!this.state.ticking) {
      window.requestAnimationFrame(() => {
        let isGoingDown = false;
        let isOutOfMain = false;
        let showDarkHeader = false;
        if (this.state.lastScrollY < event.pageY) {
          isGoingDown = true;
        }

        if (event.pageY > this.mainRef.current.offsetHeight) {
          isOutOfMain = true;
        }

        if (isOutOfMain) {
          showDarkHeader = true;
        }

        this.setState({
          showDarkHeader,
          lastScrollY: event.pageY
          // ticking: false
        });
        ticking = false;
      });
      ticking = true;
      // this.setState({ ticking: true });
    }
  }

  handleFormSubmit(event) {
    console.log("Submitting");
    event.preventDefault();
    const email = event.target.email.value;
    if (!email) {
      return;
    }
    this.setState({
      subscription: {
        inProgress: true,
        success: false,
        message: undefined
      }
    });
    var functions = firebase.functions();
    const subscribeWithEmail = functions.httpsCallable("subscribeWithEmail");
    subscribeWithEmail({ email })
      .then(result => {
        console.log(result);
        console.log(this.emailInput);
        this.emailInput.current.value = "";
        this.setState({
          subscription: {
            inProgress: false,
            success: true,
            message: undefined
          }
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    return (
      <div className="App">
        <main className="full-height" ref={this.mainRef}>
          <Header />
          <Header dark={true} show={this.state.showDarkHeader} />
          <section className="first-section-container section-center">
            <div className="first-section w-70 sm-w80">
              <p className="tagline bold">
                Feed your <span className="ip-blue">Innovation Culture.</span>{" "}
              </p>
              <p className="explanations light mg-bottom-0 sm-larger-txt">
                Subscribe to our channel and get innovation articles &amp;
                videos directly in your inbox. For free.
              </p>
              {/* <p className="sub-explanations thin sm-larger-txt">
                - Mission feedbacks - Analysis - Methodological points -
              </p> */}
              {!this.state.subscription.success ? (
                <form
                  className="newsletter-form"
                  onSubmit={this.handleFormSubmit.bind(this)}
                >
                  <input
                    type="email"
                    aria-label="Email"
                    id="email"
                    name="email"
                    placeholder="email@domain.com"
                    ref={this.emailInput}
                    className="w-30 light"
                    disabled={this.state.subscription.inProgress}
                  />
                  <input
                    className="ip-blue w-20"
                    type="submit"
                    value="Subscribe"
                    disabled={this.state.subscription.inProgress}
                  />
                  <p className="light mg-top-06 size-07">
                    Innovation insights only, zero promotional content.
                  </p>
                </form>
              ) : (
                ""
              )}
              {this.state.subscription.success ? (
                <p className="success">
                  Thank you, you have been successfully subscribed.
                </p>
              ) : (
                ""
              )}
              {/* <p className="contact-us">
                Ecrivez-nous à{" "}
                <a href="mailto:contact@innopunch.com">contact@innopunch.com</a>{" "}
                &nbsp;👊&nbsp;
              </p> */}
            </div>
          </section>
        </main>

        <section className="full-height bg-white section-center">
          <References />
        </section>
        <section className="full-height  bg-f8 section-center">
          <Team />
        </section>
        <section className="bg-white section-center">
          <Work />
        </section>

        <section className="bg-f8 section-center">
          <Contact />
        </section>

        <footer className="light">
          <div>
            <ul>
              <li>
                <a
                  href="https://www.medium.com/innopunch"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fab fa-15x fa-medium"
                    aria-label="Medium icon"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCrLYr6Fh4QL0h-KcZtwOB4w"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fab fa-15x fa-youtube"
                    aria-label="Youtube icon"
                  />
                </a>
              </li>
            </ul>
          </div>
          <p>
            <a href="mailto:contact@innopunch.com">contact@innopunch.com</a> -
            &copy; InnoPunch 2018 - All rights reserved
          </p>
        </footer>
      </div>
    );
  }
}

export default App;
